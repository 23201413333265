import React, { useState } from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Image from "../components/image/personen_thumbnails"
import { withTrans } from "../i18n/withTrans"
import SearchBar from "../components/searchbar"

import { FixedSizeList as List } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

const WIDTH = 250;

const Personen = ({t}) => {
  const query = useStaticQuery(graphql`
    query allPersonenQuery {
      allGooglePersonenSheet(sort: {fields: [nachname, vorname]}) {
        nodes {
          nachname
          portraiturl
          vorname
        }
      }
      allGoogleDozentenSheet {
        nodes {
          nachname
          portraiturl
          vorname
        }
      }
    }
  `)
    const [state, setState] = useState({
        query: "",
    })

    const handleInputChange = (change) => {setState({query: change.target.value});}
    const clearQuery = () => {setState({query: ""});}
    const isUnfiltered = (p) => {return !state.query
        || p.vorname.toLowerCase().includes(state.query.toLowerCase())
        || p.nachname.toLowerCase().includes(state.query.toLowerCase())
    }

    const getFact = (width) => {
      return Math.min(5, Math.ceil(width/WIDTH))
    }

    const LastRow = ({ index, style })=>(
      <footer
        style={{
          height:"100%",
          padding: "10px",
          zIndex:"3",
          background: "#b2b2b2",
        }}
      >
          <StaticImage
              src="../images/logo_alt.png"
              style={{width:"50px", margin: "0 auto", marginBottom: "5px", marginTop: "20px", marginLeft:"calc(50vw - 40px)"}}
              placeholder="tracedSVG"
              alt="logo"/>
          <p className="p11G" style={{textAlign: "center", marginBottom: "50px"}}>
          <Link to="/impressum" style={{textDecoration:"none", color:"black"}}>
              {t("impressum")}
          </Link>
          <span> | </span>
          <Link to="/datenschutz" style={{textDecoration:"none", color:"black"}}>
              {t("datenschutz")}
          </Link>
          </p>
      </footer>
    )


    const Row = ({ index, style, data }) => {
      const fact = getFact(data.width);
      const perc = ((100/fact).toPrecision(2)+"%");
      // Dozierende Überschrift
      if (index === 0) {
        return (<>
          <div style={style}>
            <div style={{margin:"0 auto", display:"block", width:"min(100%, "+(WIDTH*fact)+"px)"}}>
              <p style={{fontFamily:"BlueMono", fontSize:"11pt", color: "black", margin:"20px", marginTop:(WIDTH+50)/2, padding:"20px"}}>
                {t("dozentys")}
              </p>
            </div>
          </div>
        </>)
      } else if (index === 1) {
      // Dozierende
        return (<>
          <div style={style}>
            <div style={{margin:"0 auto", display:"block", width:"min(100%, "+(WIDTH*fact)+"px)"}}>
            <div style={{display: "flex", flexWrap: "wrap", height:"350px", padding:"20px"}}>
            { query.allGoogleDozentenSheet.nodes.map((person)=>(
              <div style={{flex: perc, maxWidth: "min(calc("+perc+" - 10px), "+WIDTH+"px)" }}>
                <div style={{margin:"10px"}}>
                  <Link to={`${(person.vorname.toLowerCase() + `-` + person.nachname.toLowerCase())}`}
                      style={{textDecoration: "none", display: isUnfiltered(person)?"block":"none"}}
                      key={person.vorname+person.nachname}>
                      <div style={{margin:"10px", width:"100%"}}>
                          <Image
                            src={person.portraiturl?("dozenten/"+(person.portraiturl)+".jpg") : "personen/placeholder.png"}
                            style={{
                              width:"100%",
                              height:"0", paddingBottom:"100%",
                              border:"#696969", borderWidth:"2px", borderStyle:"solid",}}
                            imgStyle={{
                               objectFit: "cover",
                             }}
                          />
                          <p style={{fontFamily:"Gotham", fontSize:"11pt", color: "black", textDecoration:"none", lineHeight: "14pt", marginTop:"10px"}}>
                              {person.vorname} <br/> {person.nachname}
                          </p>
                      </div>
                  </Link>
                </div>
              </div>
            ))}
            </div>

            </div>
          </div>
        </>)
      }else if (index === 2) {
        // Studierende Überschrift
        return (<>
          <div style={style}>
            <div style={{margin:"0 auto", display:"block", width:"min(100%, "+(WIDTH*fact)+"px)"}}>
              <p style={{fontFamily:"BlueMono", fontSize:"11pt", color: "black", margin:"20px",marginTop:(WIDTH+50)/2, padding:"20px"}}>
                {t("studys")}
              </p>
            </div>
          </div>
        </>)
      }else if (index >= Math.ceil(query.allGooglePersonenSheet.nodes.length/fact)+4) {
        // Footer
        return (<>
          <div style={style}>
            <LastRow/>
          </div>
        </>)
      } else {
        // Studierende
        return (
          <div style={style}>
            <div style={{margin:"0 auto", display:"block", width:"min(100%, "+(WIDTH*fact)+"px)"}}>
            <div style={{display: "flex", flexWrap: "wrap", height:"350px", padding:"20px"}}>

            { query.allGooglePersonenSheet.nodes.filter(isUnfiltered).slice((fact*(index-3)), (fact*(index-3))+fact).map((person)=>(
              <div style={{flex: perc, maxWidth: "min(calc("+perc+" - 10px), "+WIDTH+"px)" }}>
                <div style={{margin:"10px"}}>
                  <Link to={`${(person.vorname.toLowerCase() + `-` + person.nachname.toLowerCase())}`}
                      style={{textDecoration: "none", display: isUnfiltered(person)?"block":"none"}}
                      key={person.vorname+person.nachname}>
                      <div style={{margin:"10px", width:"100%"}}>
                          <Image
                            src={person.portraiturl?("personen/"+(person.portraiturl)+".jpg") : "personen/placeholder.png"}
                            style={{
                              width:"100%",
                              height:"0", paddingBottom:"100%",
                              border:"#696969", borderWidth:"2px", borderStyle:"solid",}}
                            imgStyle={{
                               objectFit: "cover",
                             }}
                          />
                          <p style={{fontFamily:"Gotham", fontSize:"11pt", color: "black", textDecoration:"none", lineHeight: "14pt", marginTop:"10px"}}>
                              {person.vorname} <br/> {person.nachname}
                          </p>
                      </div>
                  </Link>
                </div>
              </div>
            ))}
            </div>

            </div>
          </div>
        )
      }};

    return (
      <>
        <Seo title="Personen"/>
        <style>{"html{overflow-y:hidden;}"}</style>
        <SearchBar handler={handleInputChange} clearer={clearQuery}/>
        <main id="maincontent">
        <div style={{margin:"0 auto", width:"100%", height:"100vh", position:"fixed", top:"0", overflow:"hidden"}}>
          <AutoSizer
            defaultWidth={0}
            defaultHeight={0}>
            {({ height, width }) => (
              <List
                className="List"
                width={width}
                height={height}
                style={{overflowX:"hidden", position:"relative"}}
                itemCount={ Math.ceil(query.allGooglePersonenSheet.nodes.filter(isUnfiltered).length/getFact(width))+5}
                itemSize={Math.min(width/getFact(width)+70, WIDTH+50)}
                itemData={{ width: width, height: height }}
                rowRenderer={Row}
                >
                {Row}
              </List>
            )}
          </AutoSizer>
        </div>
        </main>
      </>
    )
}

export default withTrans(Personen)

// <AutoSizer>
//   {({ height, width }) => (
//     <List
//       className="List"
//       width={width}
//       height={height}
//       itemCount={Math.ceil(data.allGooglePersonenSheet.nodes.length/4)}
//       itemSize={320}
//     >
//       {Row}
//     </List>
//   )}
// </AutoSizer>
//
// <div style={{marginLeft: "15vw", marginRight:"15vw",marginTop:"20px", height:"100%"}}>
//
//     <Searchbar handler={handleInputChange}/>
//
//     <p style={{fontFamily:"BlueMono", fontSize:"11pt", color: "black", marginTop:"30vh"}}>
//         {t("studys")}
//     </p>
//
// </div>
